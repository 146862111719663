<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Dashboard </span>
      </v-flex>
      <!-- <v-flex xs12 py-12>
        <v-layout wrap justify-center>
          <v-flex xs12 align-self-center lg4 pa-2 v-for="(item,i) in dummy" :key="i">
            <v-card color="#EEEECE" elevation="3" class="rounded-xl" outlined height="120px">
              <v-layout wrap justify-center fill-height>
                <v-flex xs9 align-self-center pa-2>
                  <span class="popsemibold">
                    {{ item.text }}
                  </span>
                </v-flex>
                <v-flex xs3 pa-2 align-self-center>
                  <span style="font-size:38px;color:red" class="popsemibold">
                    {{ item.count }}
                  </span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex> -->
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
// import Analytics from "./analytics";
// import GraphData from "./graphData";

export default {
  components: {
    // Analytics,
    // GraphData,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dashboard: {},
      dummy:[
        {
          text: "ANIMAL LIVES SAVED",
          count: 90
        },
        {
          text: "AREA OF NATURAL HABITAT SECURED",
          count: 24
        },
        {
          text: "THREATENED SPECIES RECOVERED",
          count: 26
        },
        {
          text: "ELEPHANTS SAVED FROM TRAIN COLLISIONS",
          count: 46
        },
        {
          text: "ENFORCEMENT OPERATIONS TO DISMANTLE WILDLIFE TRADE AND TRAFFICKING",
          count: 30
        },
        {
          text: "FOREST GUARDS TRAINED AS A PART OF VAN RAKSHAK PROJECT",
          count: 177
        },
        {
          text: "FAMILIES ENABLED WITH GREEN LIVELIHOODS, REDUCING DEPENDENCE ON WILDLIFE",
          count: 200
        },
        {
          text: "NUMBER OF CHILDREN MADE AWARE OF CONSERVATION",
          count: 266
        },
      ]
    };
  },
  beforeMount() {
    // this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/dashboard/admin",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.dashboard = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>